import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_admin/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { getCSSVariableValue } from "../../_admin/assets/ts/_utils";
import { WithChildren } from "../../_admin/helpers";
import AdvertPage from "../modules/advertisement/index";
import PlatformFees from "../modules/platform-fees/index";
import ContactPage from "../modules/contact-inquiries/index";
import AllProducts from "../modules/all-products/index";
// import Customers from "../modules/customers/index";
import Customers from "../modules/customers/index";
import Orders from "../modules/orders-delivery/index";
// import InventoryPage from "../modules/Inventory";
import CustomerService from "../modules/customer-service/index";
import PaymentsIndex from "../modules/finance";
import OrdersDelivery from "../modules/orders-delivery/orders-delivery-finance";
import DashboardWrapper from "../modules/dashboard/dashboard";
import DashboardIndex from "../modules/dashboard";
import CustomNotification from "../modules/custom-notification/index";
import FinanceReport from "../modules/finance-reports/index";
import SettingPages from "../modules/settings";
import Reports from "../modules/reports";
import Method from "../../utils/methods";
import { useAuth } from "../modules/auth";
import InterTransferRequestPage from "../modules/inter-transfer-request";
import CustomerCategoryPage from "../modules/customer-category";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const SellerPage = lazy(() => import("../modules/seller/index"));
  const MasterPage = lazy(() => import("../modules/master/index"));
  const ProductPage = lazy(() => import("../modules/products/index"));
  const InventoryPage = lazy(() => import("../modules/Inventory/index"));
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path="auth/*"
          element={
            <Navigate to={Method.getFirstAuthorizedRoute(currentUser)} />
          }
        />
        {/* Pages */}
        <Route path="dashboard/*" element={<DashboardIndex />} />
        <Route
          path="orders-delivery/*"
          element={
            <SuspensedView>
              <OrdersDelivery />
            </SuspensedView>
          }
        />
        <Route
          path="sellers/*"
          element={
            <SuspensedView>
              <SellerPage />
            </SuspensedView>
          }
        />
        <Route
          path="reports/*"
          element={
            <SuspensedView>
              <Reports />
            </SuspensedView>
          }
        />
        {/* <Route
          path="products/*"
          element={
            <SuspensedView>
              <ProductsIndex />
            </SuspensedView>
          }
        /> */}
        <Route
          path="all-products/*"
          element={
            <SuspensedView>
              <AllProducts />
            </SuspensedView>
          }
        />
        <Route
          path="master/*"
          element={
            <SuspensedView>
              <MasterPage />
            </SuspensedView>
          }
        />
        <Route
          path="finance-reports/*"
          element={
            <SuspensedView>
              <FinanceReport />
            </SuspensedView>
          }
        />
        <Route
          path="customer-service/*"
          element={
            <SuspensedView>
              <CustomerService />
            </SuspensedView>
          }
        />
        <Route
          path="products/*"
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path="advertisement/*"
          element={
            <SuspensedView>
              <AdvertPage />
            </SuspensedView>
          }
        />
        <Route
          path="platform-fees/*"
          element={
            <SuspensedView>
              <PlatformFees />
            </SuspensedView>
          }
        />
        <Route
          path="contact-inquiries/*"
          element={
            <SuspensedView>
              <ContactPage />
            </SuspensedView>
          }
        />
        <Route
          path="customers/*"
          element={
            <SuspensedView>
              <Customers />
            </SuspensedView>
          }
        />
        <Route
          path="payments/*"
          element={
            <SuspensedView>
              <PaymentsIndex />
            </SuspensedView>
          }
        />
        <Route
          path="all-products/*"
          element={
            <SuspensedView>
              <AllProducts />
            </SuspensedView>
          }
        />
        <Route
          path="orders/*"
          element={
            <SuspensedView>
              <Orders />
            </SuspensedView>
          }
        />
        <Route
          path="master/*"
          element={
            <SuspensedView>
              <MasterPage />
            </SuspensedView>
          }
        />
        <Route
          path="customer-service/*"
          element={
            <SuspensedView>
              <CustomerService />
            </SuspensedView>
          }
        />
        <Route
          path="inventory/*"
          element={
            <SuspensedView>
              <InventoryPage />
            </SuspensedView>
          }
        />
        <Route
          path="customer-category/*"
          element={
            <SuspensedView>
              <CustomerCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path="inter-transfer-request/*"
          element={
            <SuspensedView>
              <InterTransferRequestPage />
            </SuspensedView>
          }
        />
        <Route
          path="products/*"
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path="advertisement/*"
          element={
            <SuspensedView>
              <AdvertPage />
            </SuspensedView>
          }
        />
        <Route
          path="custom-notification/*"
          element={
            <SuspensedView>
              <CustomNotification />
            </SuspensedView>
          }
        />
        <Route
          path="platform-fees/*"
          element={
            <SuspensedView>
              <PlatformFees />
            </SuspensedView>
          }
        />
        <Route
          path="contact-inquiries/*"
          element={
            <SuspensedView>
              <ContactPage />
            </SuspensedView>
          }
        />
        <Route
          path="settings/*"
          element={
            <SuspensedView>
              <SettingPages />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: { "0": baseColor },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
export { PrivateRoutes };
