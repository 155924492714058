import { IAddProduct, IListProducts, IUpdateProductState } from "../../types";
import {
  CartonWithPieces,
  CartonWithDozens,
  Dozen,
  MultiLineText,
  MultiSelection,
  Piece,
  SingleLineText,
  SingleSelection,
  DateSelection,
} from "../../utils/constants";
export const productJSON = {
  listProduct: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    categories,
    searchTerm,
    state,
  }: IListProducts) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: pageNo === 1 ? true : false,
      searchTerm: searchTerm,
      state: state,
      categories: categories,
    };
  },
  updateProductState: ({ activate }: IUpdateProductState) => {
    return { activate: activate };
  },
  addProduct: ({ product }: IAddProduct) => {
    let form: any = new FormData();
    form.append("isDraft", product.isDraft);
    form.append("category", product.primaryCategory);
    form.append("subCategory", product.subCategory);
    form.append("title", product.productTitle);
    form.append("taxFree", product.taxFree);
    if (product.groupCategory)
      form.append("groupCategory", product.groupCategory);
    if (!product.taxFree) form.append("productTax", product.productTax);
    if (product.brand) form.append("brand", product.brand);
    if (product.country) form.append("country", product.country);
    if (product.description) form.append("description", product.description);
    if (
      product.overrideVariantsTechnicalInfo !== undefined ||
      product.overrideVariantsTechnicalInfo !== null
    )
      form.append(
        "overrideVariantsTechnicalInfo",
        product.overrideVariantsTechnicalInfo
      );
    if (
      product.defaultTechnicalFields &&
      product.defaultTechnicalFields.length
    ) {
      product.defaultTechnicalFields.map((techVal: any, techIndex: number) => {
        form.append("technicalInfo[" + techIndex + "][reference]", techVal._id);
        if (
          techVal.type === SingleLineText ||
          techVal.type === MultiLineText ||
          techVal.type === DateSelection
        ) {
          form.append(
            "technicalInfo[" + techIndex + "][answer]",
            techVal.answer
          );
        }
        if (
          techVal.type === MultiSelection ||
          techVal.type === SingleSelection
        ) {
          if (techVal.selectedOptions && techVal.selectedOptions.length) {
            techVal.selectedOptions.map(
              (optionVal: any, optionIndex: number) => {
                form.append(
                  "technicalInfo[" +
                    techIndex +
                    "][options][" +
                    optionIndex +
                    "]",
                  optionVal
                );
              }
            );
          }
        }
      });
    }
    if (product.selectedVariant && product.selectedVariant.length) {
      product.selectedVariant.map((variantVal: any, variantIndex: number) => {
        form.append(
          "definedVariantsTypes[" + variantIndex + "][reference]",
          variantVal._id
        );
        console.log(variantVal);
        variantVal.options.map((optionVal: any, optionIndex: number) => {
          form.append(
            "definedVariantsTypes[" +
              variantIndex +
              "][options][" +
              optionIndex +
              "]",
            optionVal.title
          );
        });
      });
    }
    if (product.combinations.length) {
      product.combinations.map((combVal: any, index: number) => {
        let i = 0;
        if (
          combVal.selectedCombinations &&
          combVal.selectedCombinations.length
        ) {
          combVal.selectedCombinations.map(
            (selectVal: any, selectIndex: number) => {
              form.append(
                "variants[" +
                  index +
                  "][variantType][" +
                  selectIndex +
                  "][reference]",
                selectVal.id
              );
              form.append(
                "variants[" +
                  index +
                  "][variantType][" +
                  selectIndex +
                  "][option]",
                selectVal.title
              );
            }
          );
        }
        if (combVal.images && combVal.images.length > 0) {
          combVal.images.map((imageVal: any, imageIndex: number) => {
            if (imageVal.obj) {
              let arr: any = imageVal.obj.split(",");
              let mime = arr[0].match(/:(.*?);/)[1];
              let bstr = atob(arr[1]);
              let n = bstr.length;
              let u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
              }
              let tempImage = new File([u8arr], product.productTitle + ".png", {
                type: "image/png",
              });
              form.append(
                "variants[" + index + "][media][" + imageIndex + "][file]",
                tempImage
              );
            }
            if (imageVal._id) {
              form.append(
                "variants[" + index + "][media][" + imageIndex + "][reference]",
                imageVal._id
              );
            }
            form.append(
              "variants[" + index + "][media][" + imageIndex + "][index]",
              imageIndex.toString()
            );
          });
        }
        // if (combVal.cartoonDozens)

        // if (combVal.piecesDozens)
        // Initialize form data - using set instead of append to avoid duplicates
        form.set("variants[" + index + "][dozensInCarton]", "0");
        form.set("variants[" + index + "][piecesInDozen]", "0");
        form.set("variants[" + index + "][piecesInCarton]", "0");

        // Handle carton with dozens
        if (combVal.cartonWithDozens) {
          form.set(
            "variants[" + index + "][dozensInCarton]",
            combVal.cartoonDozens
          );
          form.set(
            "variants[" + index + "][piecesInDozen]",
            combVal.piecesDozens
          );
        }

        // Handle carton with pieces
        if (combVal.cartonWithPieces) {
          form.set(
            "variants[" + index + "][piecesInCarton]",
            combVal.cartoonPieces
          );
        }

        // Handle dozen checked
        if (combVal.dozenChecked && !combVal.cartonWithDozens) {
          form.set(
            "variants[" + index + "][piecesInDozen]",
            combVal.piecesDozens
          );
        }
        
        if (combVal.cartonWithDozens || combVal.cartonWithPieces) {
          form.append(
            "variants[" + index + "][quantityTypes][" + i + "][type]",
            combVal.cartonWithDozens
              ? CartonWithDozens.toString()
              : CartonWithPieces.toString()
          );
          if (combVal.cartoonPrice)
            form.append(
              "variants[" + index + "][quantityTypes][" + i + "][amount]",
              combVal.cartoonPrice
            );
          if (combVal.cartoonDimenisionL)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][length]",
              combVal.cartoonDimenisionL
            );
          if (combVal.cartoonDimenisionW)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][width]",
              combVal.cartoonDimenisionW
            );
          if (combVal.cartoonDimenisionH)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][height]",
              combVal.cartoonDimenisionH
            );
          if (combVal.cartoonWeight)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][weight]",
              combVal.cartoonWeight
            );
          if (combVal.cartoonDiscount == "1") {
            form.append(
              "variants[" + index + "][quantityTypes][" + i + "][discountAmt]",
              null
            );
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][discountByQuantitiesEnabled]",
              "true"
            );
            if (combVal.cartoonQuantities) {
              combVal.cartoonQuantities.map(
                (discVal: any, discIndex: number) => {
                  if (discVal.min)
                    form.append(
                      "variants[" +
                        index +
                        "][quantityTypes][" +
                        i +
                        "][discountsByQuantities][" +
                        discIndex +
                        "][min]",
                      discVal.min
                    );
                  if (discVal.max)
                    form.append(
                      "variants[" +
                        index +
                        "][quantityTypes][" +
                        i +
                        "][discountsByQuantities][" +
                        discIndex +
                        "][max]",
                      discVal.max
                    );
                  if (discVal.discount)
                    form.append(
                      "variants[" +
                        index +
                        "][quantityTypes][" +
                        i +
                        "][discountsByQuantities][" +
                        discIndex +
                        "][discountAmt]",
                      discVal.discount
                    );
                }
              );
            }
          } else {
            if (combVal.cartoonDiscountPrice)
              form.append(
                "variants[" +
                  index +
                  "][quantityTypes][" +
                  i +
                  "][discountAmt]",
                combVal.cartoonDiscountPrice
              );
          }
          i++;
        }
        if (combVal.pieceChecked) {
          form.append(
            "variants[" + index + "][quantityTypes][" + i + "][type]",
            Piece.toString()
          );
          if (combVal.piecePrice)
            form.append(
              "variants[" + index + "][quantityTypes][" + i + "][amount]",
              combVal.piecePrice
            );
          if (combVal.pieceDimenisionL)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][length]",
              combVal.pieceDimenisionL
            );
          if (combVal.pieceDimenisionW)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][width]",
              combVal.pieceDimenisionW
            );
          if (combVal.pieceDimenisionH)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][height]",
              combVal.pieceDimenisionH
            );
          if (combVal.pieceWeight)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][weight]",
              combVal.pieceWeight
            );
          if (combVal.pieceDiscount == "1") {
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][discountByQuantitiesEnabled]",
              "true"
            );
            form.append(
              "variants[" + index + "][quantityTypes][" + i + "][discountAmt]",
              null
            );
            if (combVal.pieceQuantities.length) {
              combVal.pieceQuantities.map((discVal: any, discIndex: number) => {
                if (discVal.min)
                  form.append(
                    "variants[" +
                      index +
                      "][quantityTypes][" +
                      i +
                      "][discountsByQuantities][" +
                      discIndex +
                      "][min]",
                    discVal.min
                  );
                if (discVal.max)
                  form.append(
                    "variants[" +
                      index +
                      "][quantityTypes][" +
                      i +
                      "][discountsByQuantities][" +
                      discIndex +
                      "][max]",
                    discVal.max
                  );
                if (discVal.discount)
                  form.append(
                    "variants[" +
                      index +
                      "][quantityTypes][" +
                      i +
                      "][discountsByQuantities][" +
                      discIndex +
                      "][discountAmt]",
                    discVal.discount
                  );
              });
            }
          } else {
            if (combVal.pieceDiscountPrice)
              form.append(
                "variants[" +
                  index +
                  "][quantityTypes][" +
                  i +
                  "][discountAmt]",
                combVal.pieceDiscountPrice
              );
          }
          i++;
        }
        if (combVal.dozenChecked) {
          form.append(
            "variants[" + index + "][quantityTypes][" + i + "][type]",
            Dozen.toString()
          );
          if (combVal.dozenPrice)
            form.append(
              "variants[" + index + "][quantityTypes][" + i + "][amount]",
              combVal.dozenPrice
            );
          if (combVal.dozenDimenisionL)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][length]",
              combVal.dozenDimenisionL
            );
          if (combVal.dozenDimenisionW)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][width]",
              combVal.dozenDimenisionW
            );
          if (combVal.dozenDimenisionH)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][height]",
              combVal.dozenDimenisionH
            );
          if (combVal.dozenWeight)
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][dimensions][weight]",
              combVal.dozenWeight
            );
          if (combVal.dozenDiscount == "1") {
            form.append(
              "variants[" +
                index +
                "][quantityTypes][" +
                i +
                "][discountByQuantitiesEnabled]",
              "true"
            );
            form.append(
              "variants[" + index + "][quantityTypes][" + i + "][discountAmt]",
              null
            );
            if (combVal.dozenQuantities) {
              combVal.dozenQuantities.map((discVal: any, discIndex: number) => {
                if (discVal.min)
                  form.append(
                    "variants[" +
                      index +
                      "][quantityTypes][" +
                      i +
                      "][discountsByQuantities][" +
                      discIndex +
                      "][min]",
                    discVal.min
                  );
                if (discVal.max)
                  form.append(
                    "variants[" +
                      index +
                      "][quantityTypes][" +
                      i +
                      "][discountsByQuantities][" +
                      discIndex +
                      "][max]",
                    discVal.max
                  );
                if (discVal.discount)
                  form.append(
                    "variants[" +
                      index +
                      "][quantityTypes][" +
                      i +
                      "][discountsByQuantities][" +
                      discIndex +
                      "][discountAmt]",
                    discVal.discount
                  );
              });
            }
          } else {
            if (combVal.dozenDiscountPrice)
              form.append(
                "variants[" +
                  index +
                  "][quantityTypes][" +
                  i +
                  "][discountAmt]",
                combVal.dozenDiscountPrice
              );
          }
          i++;
        }
      });
    }
    return form;
  },
  editProductVariant: ({ product }: IAddProduct) => {
    let form = new FormData();
    form.append("isDraft", product.isDraft);
    form.append("title", product.productTitle);
    if (
      product.defaultTechnicalFields &&
      product.defaultTechnicalFields.length
    ) {
      product.defaultTechnicalFields.map((techVal: any, techIndex: number) => {
        form.append("technicalInfo[" + techIndex + "][reference]", techVal._id);
        if (
          techVal.type === SingleLineText ||
          techVal.type === MultiLineText ||
          techVal.type === DateSelection
        ) {
          form.append(
            "technicalInfo[" + techIndex + "][answer]",
            techVal.answer
          );
        }
        if (
          techVal.type === MultiSelection ||
          techVal.type === SingleSelection
        ) {
          techVal.options.map((optionVal: any, optionIndex: number) => {
            if (techVal.selectedOptions.includes(optionVal._id)) {
              form.append(
                "technicalInfo[" +
                  techIndex +
                  "][options][" +
                  optionIndex +
                  "]",
                optionVal._id
              );
            }
          });
        }
        form.append("technicalInfo[" + techIndex + "][type]", techVal.type);
      });
    }
    form.append("techInfoSeparated", product.techInfoSeparated);
    if (product.combinations.length) {
      product.combinations.map((combVal: any, index: number) => {
        let i = 0;
        if (
          combVal.selectedCombinations &&
          combVal.selectedCombinations.length
        ) {
          combVal.selectedCombinations.map(
            (selectVal: any, selectIndex: number) => {
              form.append(
                "variants[" +
                  index +
                  "][variantType][" +
                  selectIndex +
                  "][reference]",
                selectVal.id
              );
              form.append(
                "variants[" +
                  index +
                  "][variantType][" +
                  selectIndex +
                  "][option]",
                selectVal.title
              );
            }
          );
        }
        if (combVal.images && combVal.images.length > 0) {
          combVal.images.map((imageVal: any, imageIndex: number) => {
            if (imageVal.obj) {
              let arr: any = imageVal.obj.split(",");
              let mime = arr[0].match(/:(.*?);/)[1];
              let bstr = atob(arr[1]);
              let n = bstr.length;
              let u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
              }
              let tempImage = new File([u8arr], product.productTitle + ".png", {
                type: "image/png",
              });
              form.append("media[" + imageIndex + "][file]", tempImage);
            }
            if (imageVal._id) {
              form.append("media[" + imageIndex + "][reference]", imageVal._id);
            }
            form.append(
              "media[" + imageIndex + "][index]",
              imageIndex.toString()
            );
          });
        }
        // Initialize form data - using set instead of append to avoid duplicates
        form.set("dozensInCarton", "0");
        form.set("piecesInDozen", "0");
        form.set("piecesInCarton", "0");

        // Handle carton with dozens
        if (combVal.cartonWithDozens) {
          form.set("dozensInCarton", combVal.cartoonDozens);
          form.set("piecesInDozen", combVal.piecesDozens);
        }

        // Handle carton with pieces
        if (combVal.cartonWithPieces) {
          form.set("piecesInCarton", combVal.cartoonPieces);
        }

        // Handle dozen checked
        if (combVal.dozenChecked && !combVal.cartonWithDozens) {
          form.set("piecesInDozen", combVal.piecesDozens);
        }

        // if (combVal.piecesDozens)
        if (combVal.cartonWithDozens || combVal.cartonWithPieces) {
          form.append(
            "quantityTypes[" + i + "][type]",
            combVal.cartonWithDozens
              ? CartonWithDozens.toString()
              : CartonWithPieces.toString()
          );
          form.append("quantityTypes[" + i + "][amount]", combVal.cartoonPrice);
          form.append(
            "quantityTypes[" + i + "][dimensions][length]",
            combVal.cartoonDimenisionL
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][width]",
            combVal.cartoonDimenisionW
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][height]",
            combVal.cartoonDimenisionH
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][weight]",
            combVal.cartoonWeight
          );
          if (combVal.cartoonDiscount == "1") {
            form.append(
              "quantityTypes[" + i + "][discountByQuantitiesEnabled]",
              "true"
            );
            combVal.cartoonQuantities.map((discVal: any, discIndex: number) => {
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][min]",
                discVal.min
              );
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][max]",
                discVal.max
              );
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][discountAmt]",
                discVal.discount
              );
            });
          } else {
            if (combVal.cartoonDiscountPrice)
              form.append(
                "quantityTypes[" + i + "][discountAmt]",
                combVal.cartoonDiscountPrice
              );
          }
          i++;
        }
        form.forEach((value, key) => {
          console.log(`${key}: ${value}`);
        });

        if (combVal.pieceChecked) {
          form.append("quantityTypes[" + i + "][type]", Piece.toString());
          form.append("quantityTypes[" + i + "][amount]", combVal.piecePrice);
          form.append(
            "quantityTypes[" + i + "][dimensions][length]",
            combVal.pieceDimenisionL
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][width]",
            combVal.pieceDimenisionW
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][height]",
            combVal.pieceDimenisionH
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][weight]",
            combVal.pieceWeight
          );
          if (combVal.pieceDiscount == "1") {
            form.append(
              "quantityTypes[" + i + "][discountByQuantitiesEnabled]",
              "true"
            );
            combVal.pieceQuantities.map((discVal: any, discIndex: number) => {
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][min]",
                discVal.min
              );
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][max]",
                discVal.max
              );
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][discountAmt]",
                discVal.discount
              );
            });
          } else {
            if (combVal.pieceDiscountPrice)
              form.append(
                "quantityTypes[" + i + "][discountAmt]",
                combVal.pieceDiscountPrice
              );
          }
          i++;
        }

        if (combVal.dozenChecked) {
          form.append("quantityTypes[" + i + "][type]", Dozen.toString());
          form.append("quantityTypes[" + i + "][amount]", combVal.dozenPrice);
          form.append(
            "quantityTypes[" + i + "][dimensions][length]",
            combVal.dozenDimenisionL
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][width]",
            combVal.dozenDimenisionW
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][height]",
            combVal.dozenDimenisionH
          );
          form.append(
            "quantityTypes[" + i + "][dimensions][weight]",
            combVal.dozenWeight
          );
          // if (combVal.pieceDiscount == '1') {
          //   form.append(
          //     'quantityTypes[' + i + '][discountByQuantitiesEnabled]',
          //     'true'
          //   );
          //   combVal.pieceQuantities.map((discVal: any, discIndex: number) => {
          //     form.append(
          //       'quantityTypes[' +
          //         i +
          //         '][discountsByQuantities][' +
          //         discIndex +
          //         '][min]',
          //       discVal.min
          //     );
          //     form.append(
          //       'quantityTypes[' +
          //         i +
          //         '][discountsByQuantities][' +
          //         discIndex +
          //         '][max]',
          //       discVal.max
          //     );
          //     form.append(
          //       'quantityTypes[' +
          //         i +
          //         '][discountsByQuantities][' +
          //         discIndex +
          //         '][discountAmt]',
          //       discVal.discount
          //     );
          //   });
          // } else {
          //   if (combVal.dozenDiscountPrice)
          //     form.append(
          //       'quantityTypes[' + i + '][discountAmt]',
          //       combVal.dozenDiscountPrice
          //     );
          // }
          if (combVal.dozenDiscount == "1") {
            form.append(
              "quantityTypes[" + i + "][discountByQuantitiesEnabled]",
              "true"
            );
            combVal.dozenQuantities.map((discVal: any, discIndex: number) => {
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][min]",
                discVal.min
              );
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][max]",
                discVal.max
              );
              form.append(
                "quantityTypes[" +
                  i +
                  "][discountsByQuantities][" +
                  discIndex +
                  "][discountAmt]",
                discVal.discount
              );
            });
          } else {
            if (combVal.dozenDiscountPrice)
              form.append(
                "quantityTypes[" + i + "][discountAmt]",
                combVal.dozenDiscountPrice
              );
          }
          i++;
        }
      });
    }
    return form;
  },
};
