import { Card, Col, Row } from "react-bootstrap";
const ViewBusinessDetails = (props: any) => {
  return (
    <>
      <Card className="border mb-8">
        <Card.Header className="bg-light align-items-center">
          <h3 className="fs-22 fw-700 mb-0">Basic details</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Row className="mb-7">
                <Col lg={4}>
                  <label className=" fs-16 fw-500 text-dark">Name:</label>
                </Col>
                <Col lg={8}>
                  <span className="fw-bolder fs-16 fw-600 text-dark">
                    {" "}
                    {props.profileData?.user.name}{" "}
                  </span>
                </Col>
              </Row>
              <Row className="mb-7">
                <Col lg={4}>
                  <label className=" fs-16 fw-500 text-dark">
                    Phone number:
                  </label>
                </Col>
                <Col lg={8}>
                  <span className="fs-16 fw-600 text-dark">
                    {props.profileData?.user.phoneCountry}{" "}
                    {props.profileData?.user.phone}
                  </span>
                </Col>
              </Row>
              <Row className="mb-7">
                <Col lg={4}>
                  <label className=" fs-16 fw-500 text-dark">TIN number:</label>
                </Col>
                <Col lg={8}>
                  <span className="fs-16 fw-600 text-dark">
                    {props.profileData?.user.business?.tinNumber
                      ? props.profileData?.user.business?.tinNumber
                      : "NA"}
                  </span>
                </Col>
              </Row>
              {props.profileData?.user.business.types.length ? (
                <Row className="mb-7">
                  <Col lg={4}>
                    <label className=" fs-16 fw-500 text-dark">
                      Business type:
                    </label>
                  </Col>
                  <Col lg={8}>
                    <span className="fs-16 fw-600 text-dark">
                      {
                        props.profileData?.user.business.types[0].reference
                          .title
                      }
                    </span>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="mb-7">
                <Col lg={4}>
                  <label className=" fs-16 fw-500 text-dark">
                    Customer category:
                  </label>
                </Col>
                <Col lg={8}>
                  <span className="fs-16 fw-600 text-dark">
                    {props.profileData?.user.category?.title || "-"}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-7">
                <Col lg={4}>
                  <label className=" fs-16 fw-500 text-dark">
                    Business name:
                  </label>
                </Col>
                <Col lg={8}>
                  <span className="fw-bolder fs-16 fw-600 text-dark">
                    {props.profileData?.user.business.name
                      ? props.profileData?.user.business.name
                      : "NA"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-7">
                <Col lg={4}>
                  <label className=" fs-16 fw-500 text-dark">Email:</label>
                </Col>
                <Col lg={8}>
                  <span className="fs-16 fw-600 text-dark">
                    {props.profileData?.user.email
                      ? props.profileData?.user.email
                      : "NA"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-7">
                <Col lg={4}>
                  <label className=" fs-16 fw-500 text-dark">VAT number</label>
                </Col>
                <Col lg={8}>
                  <span className="fs-16 fw-600 text-dark">
                    {props.profileData?.user.business.vatNumber
                      ? props.profileData?.user.business.vatNumber
                      : "NA"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-7">
                <Col lg={4}>
                  <label className=" fs-16 fw-500 text-dark">
                    Business address:
                  </label>
                </Col>
                <Col lg={8}>
                  <span className="fs-16 fw-600 text-dark">
                    {props.profileData?.user.business?.address
                      ? props.profileData?.user.business?.address
                      : "NA"}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {props.profileData?.user.deliveryAddresses.length ? (
        <Card className="border mb-8">
          <Card.Header className="bg-light align-items-center">
            <h3 className="fs-22 fw-700 mb-0">Delivery addresses</h3>
          </Card.Header>
          <Card.Body>
            <Row className="g-5">
              {props.profileData?.user.deliveryAddresses.map(
                (delivery: any, deliveryIndex: number) => {
                  return (
                    <Col md={6}>
                      <div className="border p-6 border-r10px">
                        <div className="d-flex flex-column">
                          <h5 className="fs-16 fw-600">{delivery.name}</h5>
                          <span className="fs-16 fw-600">
                            {delivery.phoneCountry} {delivery.phone}
                          </span>
                          {delivery.landmark ? (
                            <span className="fs-16 fw-600">
                              {delivery.addressLine1},{delivery.landmark},{" "}
                              {delivery.districtName},{delivery.city}
                            </span>
                          ) : (
                            <span className="fs-16 fw-600">
                              {delivery.addressLine1}, {delivery.districtName},
                              {delivery.city}
                            </span>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                }
              )}
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};
export default ViewBusinessDetails;
