import clsx from "clsx";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fileValidation } from "../../../Global/fileValidation";
import EditSVG from "../../../_admin/assets/media/svg_uTrade/edit-round.svg";
import uploadImg from "../../../_admin/assets/media/upload.png";
import APICallService from "../../../api/apiCallService";
import {
  buyer,
  customerCategory,
  customerService,
  master,
} from "../../../api/apiEndPoints";
import { customerJSON } from "../../../api/apiJSON/customer";
import { CustomSelectWhite } from "../../custom/Select/CustomSelectWhite";
import AutoComplete from "../../custom/autoComplete";
import { Add, AllCustomers, APIkey } from "../../../utils/constants";
import Method from "../../../utils/methods";
import { useAuth } from "../auth";
const AddNewCustomers = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState<{
    name: string;
    email: string;
    businessName: string;
    district: string;
    districtName: string;
    address: string;
    vatNumber: string;
    tinNumber: any;
    image: any;
    imageReader: any;
    types: string;
    phoneNumber: string;
    phoneCountry: string;
    lat: string;
    lng: string;
    category: any;
  }>({
    name: "",
    email: "",
    address: "",
    vatNumber: "",
    tinNumber: "",
    image: "",
    businessName: "",
    district: "",
    districtName: "",
    imageReader: {},
    types: "",
    phoneNumber: "",
    phoneCountry: "+255",
    lat: "1",
    lng: "1",
    category: "",
  });
  const [customerValidation, setCustomerValidation] = useState<{
    name: boolean;
    businessName: boolean;
    address: boolean;
    types: boolean;
    phoneNumber: boolean;
  }>({
    name: false,
    address: false,
    businessName: false,
    types: false,
    phoneNumber: false,
  });
  const [businessTypes, setBusinessTypes] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [customerCategoryData, setCustomerCategoryData] = useState([]);

  useEffect(() => {
    (async () => {
      if (!Method.hasPermission(AllCustomers, Add, currentUser)) {
        return window.history.back();
      }
      await fetchInitData();
      await fetchCustomerCategory();
      await fetchDistrict();
    })();
  }, []);
  const fetchInitData = async () => {
    setFetchLoading(true);
    let businessApiService = new APICallService(
      master.listBusinessTypes,
      {
        "addedFor[1]": 2,
      },
      "",
      "",
      "",
      "",
      AllCustomers
    );
    let businessResponse = await businessApiService.callAPI();
    if (businessResponse) {
      setBusinessTypes(businessResponse.records);
    }
    setFetchLoading(false);
  };
  const fetchCustomerCategory = async () => {
    let params: any = {
      needCount: true,
    };
    let apiService = new APICallService(
      customerCategory.listCustomerCategory,
      params,
      "",
      "",
      "",
      "",
      ""
    );
    let response = await apiService.callAPI();
    let data: any = [...customerCategoryData];
    if (response.records.length) {
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.title,
          title: val.title,
        });
      });
    }
    setCustomerCategoryData(data);
  };
  const handleCategorySelect = (id: string) => {
    let temp: any = { ...customerData };
    temp.category = id;
    setCustomerData(temp);
  };
  const fetchDistrict = async () => {
    let params = {};
    let apiService = new APICallService(
      customerService.listDistrict,
      params,
      "",
      "",
      "",
      "",
      AllCustomers
    );
    let response = await apiService.callAPI();
    let data: any = [...districts];
    setDistricts(response.records);
  };
  const handleBasicDetailsChange = (name: string, value: string) => {
    let temp: any = { ...customerData };
    let tempValidation: any = { ...customerValidation };
    temp[name] = value.trimStart();
    if (Object.keys(tempValidation).includes(name)) {
      tempValidation[name] = false;
      if (!value.length) tempValidation[name] = true;
    }
    setCustomerData(temp);
    setCustomerValidation(tempValidation);
  };
  const handleBusinessTypesSelect = (id: string) => {
    let temp: any = { ...customerData };
    let tempValidation: any = { ...customerValidation };
    temp.types = id;
    tempValidation.types = false;
    setCustomerData(temp);
    setCustomerValidation(tempValidation);
  };
  const handleImageChange = (event: any) => {
    const selectedFiles = event.target.files;
    let temp = { ...customerData };
    if (!selectedFiles) return;
    else {
      if (fileValidation(selectedFiles?.[0])) {
        temp.image = URL.createObjectURL(selectedFiles?.[0]);
        temp.imageReader = selectedFiles?.[0];
      }
    }
    setCustomerData(temp);
  };
  const handleAddressChange = async (
    value: string,
    lat: string,
    lng: string
  ) => {
    let tempValidation: any = JSON.parse(
      JSON.stringify({ ...customerValidation })
    );
    let temp: any = { ...customerData };
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${APIkey}`;
    await fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const results = data.results[0];
          let districtMatched = false;
          // Extract city and district from the address components

          for (const component of results.address_components) {
            console.log(component);
            if (component.types.includes("administrative_area_level_3")) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            } else if (
              component.types.includes("administrative_area_level_2")
            ) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            } else if (
              component.types.includes("administrative_area_level_1")
            ) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            }
            if (!districtMatched) {
              temp.districtName = "";
              tempValidation.district = true;
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    const matchingObjects: any = districts.filter((obj: any) => {
      // we're checking if 'searchText' is present in the 'name' property
      return obj.name.includes(temp.districtName);
    });
    if (matchingObjects.length) {
      temp.districtName = matchingObjects[0].name;
      temp.district = matchingObjects[0]._id;
    } else {
      temp.districtName = "UpCountry";
      temp.district = "651d5fa8317c6e0dcdcd8fa1";
    }
    temp["address"] = value.trimStart();
    temp["lat"] = lat;
    temp["lng"] = lng;
    setCustomerData(temp);
    if (!value.trimStart().length) {
      tempValidation.address = true;
    } else {
      tempValidation.address = false;
    }
    setCustomerValidation(tempValidation);
  };
  const handleAddCustomer = async () => {
    let temp = { ...customerData };
    let validateTemp = { ...customerValidation };
    if (temp.name === "") {
      validateTemp.name = true;
    }
    if (temp.businessName === "") {
      validateTemp.businessName = true;
    }
    if (temp.phoneNumber === "") {
      validateTemp.phoneNumber = true;
    }
    // if (temp.email === '') {
    //   validateTemp.email = true;
    // }
    if (temp.address === "") {
      validateTemp.address = true;
    }
    if (temp.types === "") {
      validateTemp.types = true;
    }
    console.log(
      validateTemp,
      Object.values(validateTemp).every((val) => val === false)
    );
    if (Object.values(validateTemp).every((val) => val === false)) {
      const matchingObjects: any = districts.filter((obj: any) => {
        // we're checking if 'searchText' is present in the 'name' property
        return obj.name.includes(temp.districtName);
      });
      if (matchingObjects.length) {
        temp.districtName = matchingObjects[0].name;
        temp.district = matchingObjects[0]._id;
      } else {
        temp.districtName = "UpCountry";
        temp.district = "651d5fa8317c6e0dcdcd8fa1";
      }
      setLoading(true);
      let apiService = new APICallService(
        buyer.addBuyer,
        customerJSON.addCustomer(temp),
        "",
        "",
        "",
        "",
        AllCustomers
      );
      let response = await apiService.callAPI();
      if (response) {
        // let apiService2 = new APICallService(buyer.addAddress, temp, {
        //   _id: ,
        // },'','','',AllCustomers);
        // let response2 = await apiService2.callAPI();
        // if (response2) {
        navigate("/customers/view-customers");
        // }
      }
      setLoading(false);
    }
    setCustomerValidation(validateTemp);
  };
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode >= 65 && charCode <= 90) || // A-Z
      (charCode >= 97 && charCode <= 122) || // a-z
      (charCode >= 48 && charCode <= 57) // 0-9
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };
  return (
    <div className="p-9">
      <Row className="mb-6">
        <Col xs={12}>
          <h1 className="fs-22 fw-bolder">Add new customers</h1>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mb-lg-0 mb-8">
          <Card className="border bg-light">
            <Card.Body>
              <Row>
                <Col xs={12}>
                  <h5 className="fs-22 fw-bolder mb-6">Business logo</h5>
                </Col>
                <Col xs={12} className="text-md-center">
                  {customerData.image ? (
                    <Col xs={12} className="text-md-center">
                      <div className="border border-r10px bg-white p-1">
                        <div className="image-input image-input-outline min-w-xl-260px min-h-xl-150px">
                          <div
                            className="image-input-wrapper shadow-none bgi-contain w-100 h-100 bgi-position-center"
                            style={{
                              // backgroundImage: `url(${sellerData.image})`,
                              backgroundRepeat: `no-repeat`,
                            }}
                          >
                            {customerData.image && (
                              <img
                                width={262}
                                height={149}
                                src={customerData.image}
                                className="img-fluid mh-150px border-r10px object-fit-contain"
                                alt=""
                              />
                            )}
                          </div>
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-50px h-50px "
                            data-kt-image-input-action="change"
                            title=""
                          >
                            <img src={EditSVG} alt="" height={33} width={33} />
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={(event) => {
                                handleImageChange(event);
                              }}
                            />
                            <input type="hidden" name="avatar_remove" />
                          </label>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col xs={12} className="text-md-center">
                      <div className="upload-btn-wrapper">
                        <div className="symbol symbol-md-235px">
                          <img
                            src={uploadImg}
                            className="img-fluid min-h-200px min-w-200px"
                            alt=""
                          />
                        </div>
                        <input
                          className="w-100 h-100"
                          type="file"
                          name="myfile"
                          onChange={(event) => {
                            handleImageChange(event);
                          }}
                        />
                      </div>
                    </Col>
                  )}
                  <span className="text-gray text-italic">{`Image dimensions:-(700x700px)`}</span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card className="bg-light pt-2 mb-6 border">
            <Card.Header className="border-bottom-0">
              <Card.Title>
                <h5 className="fs-22 fw-bolder">Customer details</h5>
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0 pb-5">
              <Row className="align-items-center">
                <Col>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="fs-16 fw-500 text-dark required">
                          Name
                        </Form.Label>
                        <Form.Control
                          className={clsx(
                            "form-control-custom bg-white",
                            customerValidation.name ? "border-danger" : ""
                          )}
                          type="text"
                          placeholder="Type here…"
                          onChange={(event: any) => {
                            handleBasicDetailsChange(
                              "name",
                              event.target.value
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="fs-16 fw-500 text-dark ">
                          Email
                        </Form.Label>
                        <Form.Control
                          className={clsx("form-control-custom bg-white")}
                          type="email"
                          placeholder="Type here…"
                          onChange={(event: any) => {
                            handleBasicDetailsChange(
                              "email",
                              event.target.value
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <div className="mb-3">
                        <Form.Label className="fs-16 fw-500 text-dark required">
                          Phone number
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text
                            className={clsx(
                              "bg-white border-right-0 fs-16 fw-600 text-black px-6",
                              customerValidation.phoneNumber
                                ? "border-danger"
                                : ""
                            )}
                          >
                            +255
                          </InputGroup.Text>
                          <Form.Control
                            name="phoneNumber"
                            className={clsx(
                              "form-control-custom border-active-none bg-white border-left-0 ps-0",
                              customerValidation.phoneNumber
                                ? "border-danger"
                                : ""
                            )}
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            onChange={(event: any) => {
                              handleBasicDetailsChange(
                                "phoneNumber",
                                event.target.value
                              );
                            }}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <div>
                          <Form.Label className="fs-16 fw-500">
                            Customer Category
                          </Form.Label>
                        </div>
                        <CustomSelectWhite
                          onChange={(event: any) => {
                            handleCategorySelect(event.value);
                          }}
                          border={customerValidation.types ? "#e55451" : ""}
                          loading={fetchLoading}
                          isDisabled={fetchLoading}
                          options={customerCategoryData}
                          isMulti={false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="bg-light mb-6 mb-xl-9 border">
            <Card.Header className="border-bottom-0">
              <Card.Title>
                <h5 className="fs-22 fw-bolder">Business details</h5>
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0 pb-5">
              <Row className="align-items-center">
                <Col>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="fs-16 fw-500 text-dark required">
                          Name
                        </Form.Label>
                        <Form.Control
                          className={clsx(
                            "form-control-custom bg-white",
                            customerValidation.businessName
                              ? "border-danger"
                              : ""
                          )}
                          type="text"
                          placeholder="Type here…"
                          onChange={(event: any) => {
                            handleBasicDetailsChange(
                              "businessName",
                              event.target.value
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <div>
                          <Form.Label className="fs-16 fw-500 required">
                            Type{" "}
                          </Form.Label>
                          {businessTypes.length &&
                            businessTypes
                              .filter((val: any) => {
                                return val._id === customerData.types;
                              })
                              .map((val: any) => {
                                return val.abbreviation ? (
                                  <span className="text-gray ms-2">
                                    {`(${val.abbreviation})`}
                                  </span>
                                ) : (
                                  <></>
                                );
                              })}
                        </div>
                        <CustomSelectWhite
                          onChange={(event: any) => {
                            handleBusinessTypesSelect(event._id);
                          }}
                          border={customerValidation.types ? "#e55451" : ""}
                          loading={fetchLoading}
                          isDisabled={fetchLoading}
                          options={
                            businessTypes.length
                              ? businessTypes.map((catval: any) => {
                                  return {
                                    value: catval.title,
                                    label: catval.title,
                                    _id: catval._id,
                                    image: catval.image,
                                    title: catval.title,
                                  };
                                })
                              : []
                          }
                          isMulti={false}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="fs-16 fw-500 text-dark required">
                          Address
                        </Form.Label>
                        <AutoComplete
                          address={customerData.address}
                          handleAddressChange={handleAddressChange}
                          lat={customerData.lat}
                          lng={customerData.lng}
                          border={clsx(
                            customerValidation.address ? "#e55451" : ""
                          )}
                        />
                        {/* <Form.Control
                          className={clsx(
                            'form-control-custom bg-white',
                            customerValidation.address ? 'border-danger' : ''
                          )}
                          type="text"
                          placeholder="Type here…"
                          onChange={(event: any) => {
                            handleBasicDetailsChange(
                              'address',
                              event.target.value
                            );
                          }}
                        /> */}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="fs-16 fw-500 text-dark">
                          TIN Number
                        </Form.Label>
                        <Form.Control
                          className={clsx("form-control-custom bg-white")}
                          type="text"
                          placeholder="Type here…"
                          value={customerData.tinNumber.toUpperCase()}
                          onChange={(event: any) => {
                            handleBasicDetailsChange(
                              "tinNumber",
                              event.target.value
                            );
                          }}
                          onKeyPress={(event: any) => {
                            handleOnKeyPress(event);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="fs-16 fw-500 text-dark">
                          VAT Number
                        </Form.Label>
                        <Form.Control
                          className={clsx("form-control-custom bg-white")}
                          type="text"
                          placeholder="Type here…"
                          value={customerData.vatNumber.toUpperCase()}
                          onChange={(event: any) => {
                            handleBasicDetailsChange(
                              "vatNumber",
                              event.target.value
                            );
                          }}
                          onKeyPress={(event: any) => {
                            handleOnKeyPress(event);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-end">
            <Button
              size="lg"
              onClick={() => {
                handleAddCustomer();
              }}
              disabled={loading}
            >
              {!loading && (
                <span className="indicator-label fs-16 fw-bold">
                  Add customers
                </span>
              )}
              {loading && (
                <span
                  className="indicator-progress fs-16 fw-bold"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default AddNewCustomers;
