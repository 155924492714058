import { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { CustomSelectWhite } from "../../../custom/Select/CustomSelectWhite";
import CrossRed from "../../../../_admin/assets/media/svg_uTrade/cross-red.svg";
import APICallService from "../../../../api/apiCallService";
import { buyer, customerCategory, master } from "../../../../api/apiEndPoints";
import { customerJSON } from "../../../../api/apiJSON/customer";
import { fileValidation } from "../../../../Global/fileValidation";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import AutoComplete from "../../../custom/autoComplete";
import { AllCustomers } from "../../../../utils/constants";
const EditBusinessDetails = (props: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [customerCategoryData, setCustomerCategoryData] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchInitData();
      await fetchCustomerCategory();
    })();
  }, []);
  const fetchInitData = async () => {
    setFetchLoading(true);
    let businessApiService = new APICallService(
      master.listBusinessTypes,
      {
        "addedFor[1]": 2,
      },
      "",
      "",
      "",
      "",
      AllCustomers
    );
    let businessResponse = await businessApiService.callAPI();
    if (businessResponse) {
      setBusinessTypes(businessResponse.records);
    }
    setFetchLoading(false);
  };
  const fetchCustomerCategory = async () => {
    let params: any = {
      needCount: true,
    };
    let apiService = new APICallService(
      customerCategory.listCustomerCategory,
      params,
      "",
      "",
      "",
      "",
      ""
      //  tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    let data: any = [...customerCategoryData];
    if (response.records.length) {
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.title,
          title: val.title,
          _id: val._id,
        });
      });
    }
    setCustomerCategoryData(data);
  };
  return (
    <>
      <>{console.log(props)}</>
      <Card className="border bg-light mb-8">
        <Card.Header className="align-items-center">
          <h3 className="fs-22 fw-700 mb-0">Basic details</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Row className="mb-7 align-items-center">
                <Col lg={4}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    Name
                  </Form.Label>
                </Col>
                <Col lg={8}>
                  <Form.Control
                    className={clsx(
                      "form-control-custom bg-white",
                      props.customerValidation.name ? "border-danger" : ""
                    )}
                    value={props.customerData.name}
                    type="text"
                    placeholder="Type here…"
                    onChange={(event: any) => {
                      props.handleBasicDetailsChange(
                        "name",
                        event.target.value
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-7 align-items-center">
                <Col lg={4}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    Phone number
                  </Form.Label>
                </Col>
                <Col lg={8}>
                  <InputGroup>
                    <InputGroup.Text
                      className={clsx(
                        "bg-white border-right-0 fs-16 fw-600 text-black px-6",
                        props.customerValidation.phoneNumber
                          ? "border-danger"
                          : ""
                      )}
                    >
                      +255
                    </InputGroup.Text>
                    <Form.Control
                      name="phoneNumber"
                      className={clsx(
                        "form-control-custom border-active-none bg-white border-left-0 ps-0",
                        props.customerValidation.phoneNumber
                          ? "border-danger"
                          : ""
                      )}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      value={props.customerData.phoneNumber}
                      disabled={true}
                      onChange={(event: any) => {
                        props.handleBasicDetailsChange(
                          "phoneNumber",
                          event.target.value
                        );
                      }}
                    />
                  </InputGroup>
                  {/* <Form.Control type="text" placeholder='Type here...'
                                                    value="Patrick Richards" className='form-control-custom bg-white'
                                                /> */}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-7 align-items-center">
                <Col lg={4}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    Business Name
                  </Form.Label>
                </Col>
                <Col lg={8}>
                  <Form.Control
                    className={clsx(
                      "form-control-custom bg-white",
                      props.customerValidation.businessName
                        ? "border-danger"
                        : ""
                    )}
                    value={props.customerData.businessName}
                    type="text"
                    placeholder="Type here…"
                    onChange={(event: any) => {
                      props.handleBasicDetailsChange(
                        "businessName",
                        event.target.value
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-7 align-items-center">
                <Col lg={4}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    Email
                  </Form.Label>
                </Col>
                <Col lg={8}>
                  <Form.Control
                    className={clsx("form-control-custom bg-white")}
                    value={props.customerData.email}
                    type="text"
                    disabled={props.customerData.email ? true : false}
                    placeholder="Type here…"
                    onChange={(event: any) => {
                      props.handleBasicDetailsChange(
                        "email",
                        event.target.value
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <Row className="mb-7 align-items-center">
                <Col lg={2}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    Business address
                  </Form.Label>
                </Col>
                <Col lg={10}>
                  <AutoComplete
                    address={props.customerData.address}
                    handleAddressChange={props.handleAddressChange}
                    lat={props.customerData.lat}
                    lng={props.customerData.lng}
                    border={clsx(
                      props.customerValidation.address ? "#e55451" : ""
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-7 align-items-center">
                <Col lg={4}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    TIN number
                  </Form.Label>
                </Col>
                <Col lg={8}>
                  <Form.Control
                    className={clsx("form-control-custom bg-white")}
                    type="text"
                    placeholder="Type here…"
                    value={props.customerData.tinNumber.toUpperCase()}
                    onChange={(event: any) => {
                      props.handleBasicDetailsChange(
                        "tinNumber",
                        event.target.value
                      );
                    }}
                    onKeyPress={(event: any) => {
                      props.handleOnKeyPress(event);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-7 align-items-center">
                <Col lg={4}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    VAT number
                  </Form.Label>
                </Col>
                <Col lg={8}>
                  <Form.Control
                    className={clsx("form-control-custom bg-white")}
                    type="text"
                    placeholder="Type here…"
                    value={props.customerData.vatNumber.toUpperCase()}
                    onChange={(event: any) => {
                      props.handleBasicDetailsChange(
                        "vatNumber",
                        event.target.value
                      );
                    }}
                    onKeyPress={(event: any) => {
                      props.handleOnKeyPress(event);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-1 align-items-center">
                <Col lg={4}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    Business type
                  </Form.Label>
                </Col>
                <Col lg={8}>
                  <>{console.log("props.customerData", props.customerData)}</>
                  <CustomSelectWhite
                    onChange={(event: any) => {
                      props.handleBusinessTypesSelect(event);
                    }}
                    border={props.customerValidation.types ? "#e55451" : ""}
                    loading={fetchLoading}
                    isDisabled={fetchLoading}
                    options={
                      businessTypes.length
                        ? businessTypes.map((catval: any) => {
                            return {
                              value: catval.title,
                              label: catval.title,
                              _id: catval._id,
                              image: catval.image,
                              title: catval.title,
                            };
                          })
                        : []
                    }
                    hideSelectedOptions={true}
                    value={
                      props.customerData.types
                        ? {
                            label: props.customerData.types.label
                              ? props.customerData.types.label
                              : props.customerData.types.title,
                            value: props.customerData.types._id,
                            title: props.customerData.types.title,
                          }
                        : null // Set to null when no match is found
                    }
                    isMulti={false}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-1 align-items-center">
                <Col lg={4}>
                  <Form.Label className="fs-16 fw-500 text-dark">
                    Customer category
                  </Form.Label>
                </Col>
                <Col lg={8}>
                  <>{console.log("props.customerData", props.customerData)}</>
                  <CustomSelectWhite
                    onChange={(event: any) => {
                      props.handleCategoryChange(event._id);
                    }}
                    border={props.customerValidation.types ? "#e55451" : ""}
                    loading={fetchLoading}
                    isDisabled={fetchLoading}
                    options={customerCategoryData}
                    hideSelectedOptions={true}
                    value={
                      props.customerData.category
                        ? customerCategoryData.find(
                            (item: any) =>
                              item._id == props.customerData.category
                          )
                        : null // Set to null when no match is found
                    }
                    isMulti={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default EditBusinessDetails;
