import { Navigate, Routes, Route, Outlet } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_admin/layout/core";
import SalesReport from "./sales-report";
import ReturnProductReport from "./return-product-report";
import DeliveryReport from "./delivery-report";
import SalesReportV2 from "./sales-report-v2";
import SalesReportV3 from "./sales-report-v3";
import SalesReportV4 from "./sales-report-v4";
import SalesReportV5 from "./sales-report-v5";
import SalesReportV6 from "./sales-report-v6";
import SalesReportV8 from "./sales-report-v8";
import SalesReportV7 from "./sales-report-v7";
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: "Profile",
    path: "/finance-reports/sales-report",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];
const ProfilePage = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path="outward-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Outward Report
            </PageTitle>
            <SalesReport />
          </>
        }
      />
      <Route
        path="v2-outward-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Outward Report(V2)
            </PageTitle>
            <SalesReportV2 />
          </>
        }
      />
      <Route
        path="v3-outward-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Outward Report(V3)
            </PageTitle>
            <SalesReportV3 />
          </>
        }
      />
      <Route
        path="v4-outward-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Outward Report(V4)
            </PageTitle>
            <SalesReportV4 />
          </>
        }
      />
      <Route
        path="v5-outward-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Outward Report(V5)
            </PageTitle>
            <SalesReportV5 />
          </>
        }
      />
      <Route
        path="v6-outward-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Outward Report(V6)
            </PageTitle>
            <SalesReportV6 />
          </>
        }
      />
      <Route
        path="v7-outward-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Outward Report(V7)
            </PageTitle>
            <SalesReportV7 />
          </>
        }
      />
      <Route
        path="v8-outward-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Outward Report(V8)
            </PageTitle>
            <SalesReportV8 />
          </>
        }
      />
      <Route
        path="return-product-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Return Product Report
            </PageTitle>
            <ReturnProductReport />
          </>
        }
      />
      <Route
        path="delivery-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Delivery Report
            </PageTitle>
            <DeliveryReport />
          </>
        }
      />
      <Route index element={<Navigate to="/finance-reports/sales-report" />} />
    </Route>
  </Routes>
);
export default ProfilePage;
