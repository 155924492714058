import {
  IAddCustomer,
  IAssignMember,
  IListAllBuyerRefundAmount,
  IListBuyerRefundOrder,
  IListCustomers,
} from "../../types";
export const customerJSON = {
  listCustomers: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    searchTerm,
    state,
  }: IListCustomers) => {
    let data: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
    };
    if (state !== undefined) {
      data = { ...data, state: state };
    }
    return data;
  },
  addCustomer: ({
    tinNumber,
    address,
    vatNumber,
    email,
    name,
    businessName,
    phoneNumber,
    phoneCountry,
    types,
    lat,
    lng,
    imageReader,
    district,
    districtName,
    deliveryAddresses,
    category,
  }: IAddCustomer) => {
    let form = new FormData();
    form.append("businessName", businessName);
    form.append("name", name);
    form.append("phone", phoneNumber);
    form.append("phoneCountry", phoneCountry);
    if (email !== "") {
      form.append("email", email);
    }
    form.append("address", address);
    form.append("district", district);
    form.append("districtName", districtName);
    form.append("lat", lat);
    form.append("lng", lng);
    form.append("vatNumber", vatNumber.toUpperCase());
    form.append("tinNumber", tinNumber.toUpperCase());
    if (imageReader && imageReader !== "") form.append("image", imageReader);
    form.append("types[" + 0 + "]", types);
    if (deliveryAddresses) {
      deliveryAddresses.forEach((address: any, index: number) => {
        Object.keys(address).forEach((key) => {
          form.append(`deliveryAddresses[${index}][${key}]`, address[key]);
        });
      });
    }
    if (category.length) {
      form.append("category", category);
    }
    //     for (const pair of form.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`)
    // }

    return form;
  },
  assignMember: ({ user, assignedTo }: IAssignMember) => {
    let form = new FormData();
    form.append("user", user);
    form.append("assignedTo", assignedTo);
    return { user: user, assignedTo: assignedTo };
  },
  listRefundOrder: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    searchTerm,
  }: IListAllBuyerRefundAmount) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
    };
  },
  listBuyerRefundOrder: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    searchTerm,
    listType,
    buyer,
  }: IListBuyerRefundOrder) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
      listType: listType,
      buyer: buyer,
    };
  },
  addCustomerCategory: ({ title, roundValue, discount1, discount2 }: any) => {
    return {
      list: [
        {
          title: title,
          roundValue: roundValue,
          discount: discount1.length ? discount1 : discount2,
          discountType: discount1.length ? 1 : 2,
        },
      ],
    };
  },
  editCustomerCategory: ({ title, roundValue, discount1, discount2 }: any) => {
    return {
      title: title,
      roundValue: roundValue,
      discount: discount1.length ? discount1 : discount2,
      discountType: discount1.length ? 1 : 2,
    };
  },
};
