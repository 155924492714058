import { Button, Card, Col, FormLabel, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  ApprovedProduct,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  OutWardReports,
  PAGE_LIMIT,
  Piece,
  SellersSalesReport,
  OrdersDelivery,
} from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import {
  buyer,
  driver,
  manageProduct,
  seller,
} from '../../../api/apiEndPoints';
import Method from '../../../utils/methods';
import Loader from '../../../Global/loader';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
import { useAuth } from '../auth';
import clsx from 'clsx';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import { listOrdersDelivery } from '../../../utils/storeString';
import { getKey, removeKey, setKey } from '../../../Global/history';
import { driverJSON } from '../../../api/apiJSON/driver';
import { error } from '../../../Global/toast';
import Notification from '../../notification/notification';
const SalesReportV6 = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [details, setDetails] = useState<any>();
  const [endDate, setEndDate] = useState<any>(new Date());
  const [startDate, setStartDate] = useState<any>(new Date());
  const [fetchLoading, setFetchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [search, setSearch] = useState<string>('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [paymentDetails, setPaymentDetails] = useState<any>([]);
  const [selectedDriver, setSelectedDriver] = useState<any>(
    getKey(listOrdersDelivery.selectedListVehicle)
  );
  const [fetchCustomerLoader, setFetchCustomerLoader] = useState(false);
  const [productsList, setProductsList] = useState<any>([]);
  const [drivers, setDrivers] = useState<any>([]);
  const [productLoading, setProductLoading] = useState(false);
  const [searchProduct, setSearchProduct] = useState('');
  const [totalProducts, setTotalProducts] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [customerData, setCustomerData] = useState<any>([]);
  const [totalCustomer, setTotalCustomer] = useState<number>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<any>('');
  const [searchCustomer, setSearchCustomer] = useState<string>('');
  const [download, setDownload] = useState(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      if (!Method.hasModulePermission(SellersSalesReport, currentUser)) {
        return window.history.back();
      }
      await fetchOrder(
        startDate,
        endDate,
        selectedDriver ? selectedDriver._id : null,
        selectedProduct ? selectedProduct.value : null,
        selectedCustomer ? selectedCustomer._id : null
      );
      // fetchDrivers();
      // fetchProducts(page, PAGE_LIMIT, searchProduct);
      setFetchLoading(false);
    })();
  }, []);
  const fetchOrder = async (
    startDate: string,
    endDate: string,
    driver: any,
    product?: any,
    customer?: any
  ) => {
    let params: any = {
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD')
        : '',
      toDate: endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      utcOffset: new Date().getTimezoneOffset(),
    };
    if (driver) {
      params = {
        ...params,
        driver: driver,
      };
    }
    if (product) {
      params = {
        ...params,
        ['variants[0]']: product,
      };
    }
    if (customer) {
      params = {
        ...params,
        customer: customer,
      };
    }
    let apiService = new APICallService(
      seller.orderSalesReportV4,
      params,
      '',
      '',
      '',
      '',
      SellersSalesReport
    );
    let response = await apiService.callAPI();
    if (response) {
      getUniqueCustomers(response);
      getUniqueDriver(response);
      setPaymentData(response);
      getUniqueProducts(response);
    }
  };
  const fetchProducts = async (
    page: number,
    limit: number,
    search: string = ''
  ) => {
    setProductLoading(true);
    let data = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: 'title',
      sortOrder: 1,
      state: 1,
      needCount: false,
      ['status[0]']: ApprovedProduct,
    };
    let apiService = new APICallService(
      manageProduct.listVariant,
      data,
      '',
      '',
      '',
      '',
      SellersSalesReport
    );
    let response = await apiService.callAPI();
    if (response.records) {
      let data: any = [];
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          name: Method.replaceSpace(val.title),
          title: Method.replaceSpace(val.title),
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val.media[0].image}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">
                {Method.replaceSpace(val.title)}
              </span>
            </>
          ),
          id: val._id,
          productId: val.product.reference,
        });
      });
      setTotalProducts(response.total);
      let data2: any = (prevResults: any) =>
        page === 1 ? response.records : [...prevResults, ...response.records];
      setProductsList(data2);
    } else {
      setProductsList([]);
    }
    setProductLoading(false);
  };
  const fetchDrivers = async () => {
    let params = {
      pageNo: 1,
      limit: 0,
      sortKey: 'name',
      sortOrder: 1,
    };
    let apiService = new APICallService(
      driver.listDriver,
      driverJSON.listDriver(params),
      '',
      '',
      '',
      '',
      OrdersDelivery
    );
    let response = await apiService.callAPI();
    if (response.records) {
      response.records = response.records.map((val: any) => {
        return {
          label: val.name,
          value: val.name,
          title: val.name,
          _id: val._id,
          image: val.image,
        };
      });
      setDrivers(response.records);
    }
  };
  const getUniqueCustomers = (data: any) => {
    const uniqueCustomers = data.reduce((acc: any, order: any) => {
      order.orderDetails.forEach((detail: any) => {
        const customerRef = detail.customer.reference;
        if (!acc.some((c: any) => c.reference === customerRef)) {
          acc.push(detail.customer);
        }
      });
      return acc;
    }, []);
    setCustomerData(uniqueCustomers);
  };
  const getUniqueProducts = (data: any) => {
    const uniqueVariants = new Set();
    data.forEach((item: any) => {
      // Access the variant information directly
      const variant = item.variant;
      const variantInfo = {
        title: variant.title,
        id: variant._id,
      };
      uniqueVariants.add(JSON.stringify(variantInfo)); // Use JSON.stringify to ensure uniqueness
    });
    // Convert the Set back to an array of objects
    const uniqueVariantsArray = Array.from(uniqueVariants).map((variant: any) =>
      JSON.parse(variant)
    );
    console.log(uniqueVariantsArray);
    setProductsList(uniqueVariantsArray);
  };
  const getUniqueDriver = (data: any) => {
    const uniqueDrivers = new Set();
    data.forEach((item: any) => {
      item.orderDetails.forEach((order: any) => {
        order.matchedRouteVehicles.forEach((vehicle: any) => {
          uniqueDrivers.add(
            JSON.stringify({
              name: vehicle.driver.name,
              reference: vehicle.driver.reference,
            })
          );
        });
      });
    });
    // Convert the Set to an array if needed
    const uniqueDriversArray = Array.from(uniqueDrivers).map((driver: any) =>
      JSON.parse(driver)
    );
    setDrivers(uniqueDriversArray);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFetchLoading(true);
      setTotalRecords(0);
      await fetchOrder(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedDriver ? selectedDriver._id : null,
        selectedProduct ? selectedProduct.value : null,
        selectedCustomer ? selectedCustomer._id : null
      );
      setFetchLoading(false);
    }
  };
  const handleDriverFilter = async (event: any) => {
    if (event) {
      setTotalRecords(0);
      setPaymentData([]);
      setFetchLoading(true);
      setSelectedDriver(event);
      setPage(1);
      setKey(listOrdersDelivery.selectedListVehicle, event, false);
      await fetchOrder(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        event._id,
        selectedProduct ? selectedProduct.value : null,
        selectedCustomer ? selectedCustomer._id : null
      );
      setFetchLoading(false);
    } else {
      setTotalRecords(0);
      setPaymentData([]);
      setFetchLoading(true);
      setSelectedDriver(null);
      removeKey(listOrdersDelivery.selectedListVehicle);
      await fetchOrder(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        null,
        selectedProduct ? selectedProduct.value : null,
        selectedCustomer ? selectedCustomer._id : null
      );
      setFetchLoading(false);
    }
  };
  const onMenuScrollToBottom = async () => {
    if (!(productsList.length === totalProducts)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      // await fetchProducts(tempPage, PAGE_LIMIT, search);
    }
  };
  const onMenuClose = async () => {
    setSearch('');
  };
  const onMenuOpen = async () => {
    setPage(1);
    // await fetchProducts(1, PAGE_LIMIT, search);
  };
  const handleInputChange = async (inputValue: string) => {
    setPage(1);
    setSearch(inputValue);
    // await fetchProducts(1, PAGE_LIMIT, inputValue);
  };
  const handleProductSelect = async (event: any) => {
    if (event) {
      setTotalRecords(0);
      setPaymentData([]);
      setFetchLoading(true);
      setSelectedProduct(event);
      setPage(1);
      await fetchOrder(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedDriver ? selectedDriver._id : null,
        event.value,
        selectedCustomer ? selectedCustomer._id : null
      );
      setFetchLoading(false);
    } else {
      setTotalRecords(0);
      setPaymentData([]);
      setFetchLoading(true);
      setSelectedProduct(null);
      await fetchOrder(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedDriver ? selectedDriver._id : null,
        null,
        selectedCustomer ? selectedCustomer._id : null
      );
      setFetchLoading(false);
    }
  };
  const fetchCustomers = async (
    page: number,
    limit: number,
    search: string = ''
  ) => {
    setFetchCustomerLoader(true);
    let params = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: 'name',
      sortOrder: 1,
      needCount: true,
      state: 1,
    };
    let apiService = new APICallService(
      buyer.listBuyer,
      params,
      '',
      '',
      '',
      '',
      OrdersDelivery
    );
    let response = await apiService.callAPI();
    if (response.records) {
      setTotalCustomer(response.total);
      let data: any = (prevResults: any) =>
        page === 1 ? response.records : [...prevResults, ...response.records];
      setCustomerData(data);
    }
    setFetchCustomerLoader(false);
  };
  const onCustomerMenuClose = async () => {
    setSearchCustomer('');
  };
  const onCustomerMenuOpen = async () => {
    setPage(1);
    // await fetchCustomers(1, PAGE_LIMIT, searchCustomer);
  };
  const handleCustomerInputChange = async (newValue: string) => {
    setPage(1);
    setSearchCustomer(newValue);
    // await fetchCustomers(1, PAGE_LIMIT, newValue);
  };
  const onCustomerMenuScrollToBottom = async () => {
    if (!(customerData.length === totalCustomer)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      // await fetchCustomers(tempPage, PAGE_LIMIT, searchCustomer);
    }
  };
  const handleCustomerChange = async (event: any) => {
    if (event) {
      console.log(selectedProduct);
      setTotalRecords(0);
      setPaymentData([]);
      setFetchLoading(true);
      setSelectedCustomer(event);
      setPage(1);
      await fetchOrder(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedDriver ? selectedDriver._id : null,
        selectedProduct ? selectedProduct.value : null,
        event.value
      );
      setFetchLoading(false);
    } else {
      setTotalRecords(0);
      setPaymentData([]);
      setFetchLoading(true);
      setSelectedCustomer(event);
      await fetchOrder(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedDriver ? selectedDriver._id : null,
        selectedProduct ? selectedProduct.value : null,
        null
      );
      setFetchLoading(false);
    }
  };
  const handleDownload = async (startDate: string, endDate: string) => {
    setDownload(true);
    let params: any = {
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD')
        : '',
      toDate: endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      download: true,
      utcOffset: new Date().getTimezoneOffset(),
    };
    if (selectedDriver) {
      params = {
        ...params,
        driver: selectedDriver._id,
      };
    }
    if (selectedProduct) {
      params = {
        ...params,
        ['variants[0]']: selectedProduct.value,
      };
    }
    if (selectedCustomer) {
      params = {
        ...params,
        customer: selectedCustomer._id,
      };
    }
    let apiService = new APICallService(
      seller.orderSalesReportV3,
      params,
      undefined,
      'arraybuffer',
      '',
      '',
      OutWardReports
    );
    let response = await apiService.callAPI();
    if (response) {
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'outward' + '.xlsx';
      link.click();
      URL.revokeObjectURL(url);
    } else {
      error('Data Not Found');
    }
    setDownload(false);
  };
  const toggleExpand = (index: number) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };
  return (
    <>
      <div>
        <Notification
          bgColor="bg-danger"
          textColor="text-white"
          text={`In this version, orders that are marked as 'outward' on the date ${Method.convertDateToDDMMYYYY(
            startDate
          )}  -  ${Method.convertDateToDDMMYYYY(
            endDate
          )} are included, while orders that have been moved to pending status or for which the customer is not available are included.`}
        />
      </div>
      <div className="p-9">
        <Row className="g-4">
          <Col md>
            <div className="d-flex align-items-center mt-4">
              <h1 className="fs-22 fw-bolder">Outward Report</h1>
            </div>
          </Col>
          <Col md="auto">
            <div className="d-flex align-items-center">
              <FormLabel className="fs-16 fw-500">Filter by Driver</FormLabel>
              <div className="ms-5">
                <CustomSelect
                  className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                  onChange={handleDriverFilter}
                  options={
                    drivers && drivers.length
                      ? drivers.map((val: any) => {
                          return {
                            label: (
                              <>
                                <span className="fs-16 fw-600 text-black mb-0">
                                  {val.name}
                                </span>
                              </>
                            ),
                            value: val.reference,
                            title: val.name,
                            _id: val.reference,
                          };
                        })
                      : []
                  }
                  hideSelectedOptions={true}
                  isClearable={true}
                  value={selectedDriver}
                  default={selectedDriver}
                />
              </div>
            </div>
          </Col>
          <Col md="auto">
            <div className="d-flex align-items-center">
              <FormLabel className="fs-16 fw-500">Filter by Product</FormLabel>
              <div className="ms-5">
                <CustomSelect
                  backgroundColor="#ffff"
                  isClearable={true}
                  options={
                    productsList && productsList.length
                      ? productsList.map((val: any) => {
                          return {
                            label: (
                              <>
                                <span className="fs-16 fw-600 text-black mb-0">
                                  {val.title}
                                </span>
                              </>
                            ),
                            value: val.id,
                            title: val.title,
                          };
                        })
                      : []
                  }
                  onChange={(event: any) => {
                    handleProductSelect(event);
                  }}
                  onMenuScrollToBottom={onMenuScrollToBottom}
                  onMenuClose={() => {
                    onMenuClose();
                  }}
                  onMenuOpen={() => {
                    onMenuOpen();
                  }}
                  onInputChange={(newValue: any, { action }: any) => {
                    if (action === 'input-change') {
                      handleInputChange(newValue);
                    }
                  }}
                  isDisabled={productLoading}
                  hideSelectedOptions={true}
                />
              </div>
            </div>
          </Col>
          <Col md="auto">
            <div className="d-flex align-items-center">
              <FormLabel className="fs-16 fw-500">Filter by customer</FormLabel>
              <div className="ms-5">
                <CustomSelect
                  hideSelectedOptions={true}
                  isLoading={fetchCustomerLoader}
                  backgroundColor="#ffff"
                  options={
                    customerData
                      ? customerData.map((val: any) => ({
                          label: (
                            <>
                              <div className="d-flex align-items-center">
                                <div className="ms-3">
                                  <span className="fs-16 fw-600 text-black mb-0">
                                    {val.business.name}
                                  </span>
                                  <div className="fs-14 fw-500 text-gray">
                                    {val.phoneCountry} {val.phone}
                                  </div>
                                </div>
                                {/* <span className="fs-16 fw-600 text-black mb-0">
                                    {val.business.name}
                                  </span> */}
                              </div>
                            </>
                          ),
                          title: val.business.name,
                          value: val.reference,
                          _id: val.reference,
                        }))
                      : []
                  }
                  onMenuClose={() => {
                    onCustomerMenuClose();
                  }}
                  onMenuOpen={() => {
                    onCustomerMenuOpen();
                  }}
                  isClearable={true}
                  onMenuScrollToBottom={onCustomerMenuScrollToBottom}
                  onInputChange={(newValue: any, { action }: any) => {
                    if (action === 'input-change') {
                      handleCustomerInputChange(newValue);
                    }
                  }}
                  onChange={(event: any) => {
                    handleCustomerChange(event);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-end mt-5">
          <Col md="auto">
            <div className="d-flex align-items-center">
              <FormLabel className="fs-16 fw-500">Filter by dates</FormLabel>
              <div className="ms-5">
                <CustomDatePicker
                  className="form-control bg-white min-h-30px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                  onChange={handleChange}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  showFullMonthYearPicker={true}
                  maxDate={new Date()}
                  inputTextBG="bg-white"
                />
              </div>
            </div>
          </Col>
          <Col md="auto">
            <Button
              className="fs-14 fw-600"
              onClick={() => handleDownload(startDate, endDate)}
              disabled={fetchLoading || download}
            >
              {!download && (
                <span className="indicator-label fs-16 fw-bold">
                  Download Excel
                </span>
              )}
              {download && (
                <span
                  className="indicator-progress fs-16 fw-bold"
                  style={{ display: 'block' }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col
            xl={4}
            md={6}
            sm={4}
          >
            <div
              className={clsx('border-r8px  rounded py-4 px-5', 'bg-fbebbc')}
            >
              <div className="d-flex align-items-center">
                <div className="fs-22 fw-bolder">
                  TSh{' '}
                  {Method.getGeneralizedAmount(
                    paymentData.reduce((p: any, c: any) => {
                      return p + c.totalAmount;
                    }, 0)
                  )}
                </div>
              </div>
              <div className="fw-500 fs-16">GRAND TOTAL ICL VAT</div>
            </div>
          </Col>
          <Col
            xl={4}
            md={6}
            sm={4}
          >
            <div
              className={clsx('border-r8px  rounded py-4 px-5', 'bg-efefef')}
            >
              <div className="d-flex align-items-center">
                <div className="fs-22 fw-bolder">
                  TSh{' '}
                  {Method.getGeneralizedAmount(
                    paymentData.reduce((p: any, c: any) => {
                      return p + c.totalTaxAmount;
                    }, 0)
                  )}
                </div>
              </div>
              <div className="fw-500 fs-16">GRAND TOTAL EXL VAT</div>
            </div>
          </Col>
          <Col
            xl={4}
            md={6}
            sm={4}
          >
            <div
              className={clsx('border-r8px  rounded py-4 px-5', 'bg-ccebfd')}
            >
              <div className="d-flex align-items-center">
                <div className="fs-22 fw-bolder">
                  TSh{' '}
                  {Method.getGeneralizedAmount(
                    paymentData.reduce((p: any, c: any) => {
                      return p + c.tax;
                    }, 0)
                  )}
                </div>
              </div>
              <div className="fw-500 fs-16">GRAND TOTAL VAT AMOUNT</div>
            </div>
          </Col>
        </Row>
        <Card className="border border-r10px mt-6">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-row-bordered datatable align-middle gs-7 gy-4 my-0">
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    <th className="min-w-150px">PRODUCT NAME</th>
                    <th className="min-w-100px">QTY</th>
                    <th className="min-w-150px">PRICE ICL VAT</th>
                    <th className="min-w-150px">PRICE EXCL VAT</th>
                    <th className="min-w-150px">VAT PER UNIT</th>
                    <th className="min-w-175px">TOTAL ICL VAT</th>
                    <th className="min-w-175px">TOTAL EXL VAT</th>
                    <th className="min-w-175px">VAT AMOUNT</th>
                    <th className="min-w-50px"></th>
                  </tr>
                </thead>
                <tbody className="fs-15 fw-600">
                  {fetchLoading ? (
                    <>
                      <tr>
                        <td colSpan={9}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={fetchLoading} />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {paymentData.length ? (
                        <>
                          {paymentData.map((val: any, index: number) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        src={val.variant.media[0].url}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <div className="d-flex flex-column">
                                      <span className="fs-15 fw-600">
                                        {val.variant.title.replace(
                                          /\s*\)\s*/g,
                                          ')'
                                        )}
                                      </span>
                                      <span className="fs-15 fw-600 text-gray">
                                        {val.variant.product.business.name}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {val.totalQuantities}{' '}
                                    {val.quantityType === CartonWithDozens ||
                                    val.quantityType === CartonWithPieces
                                      ? 'C'
                                      : ''}
                                    {val.quantityType === Dozen ? 'D' : ''}
                                    {val.quantityType === Piece ? 'P' : ''}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {'TSh ' +
                                      Method.getGeneralizedAmount(
                                        val.averagePrice
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {'TSh ' +
                                      Method.getGeneralizedAmount(
                                        val.averageTaxPrice
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {'TSh ' +
                                      Method.getGeneralizedAmount(
                                        val.taxPerUnit
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {'TSh ' +
                                      Method.getGeneralizedAmount(
                                        val.totalAmount
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {'TSh ' +
                                      Method.getGeneralizedAmount(
                                        val.totalTaxAmount
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {'TSh ' +
                                      Method.getGeneralizedAmount(val.tax)}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-icon btn-sm btn-light-primary"
                                    onClick={() => toggleExpand(index)}
                                  >
                                    <i
                                      className={`fas fa-chevron-${
                                        expandedRows.includes(index)
                                          ? 'up'
                                          : 'down'
                                      }`}
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                              {expandedRows.includes(index) && (
                                <tr className="bg-light">
                                  <td colSpan={9}>
                                    <table className="table table-row-bordered border border-r10px datatable align-middle gs-7 gy-4 my-0 bg-white">
                                      <thead>
                                        <tr className="fs-16 fw-600 h-65px align-middle">
                                          <th className="min-w-400px">
                                            ORDER ID
                                          </th>
                                          <th className="min-w-400px">
                                            CUSTOMER NAME
                                          </th>
                                          <th className="min-w-150px">QTY</th>
                                          <th className="min-w-150px">
                                            TOTAL ICL VAT
                                          </th>
                                          <th className="min-w-50px"></th>
                                        </tr>
                                      </thead>
                                      <tbody className="fs-15 fw-600">
                                        {fetchLoading ? (
                                          <>
                                            <tr>
                                              <td colSpan={9}>
                                                <div className="d-flex justify-content-center">
                                                  <Loader
                                                    loading={fetchLoading}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        ) : (
                                          <>
                                            {val.orderDetails.length ? (
                                              <>
                                                {val.orderDetails.map(
                                                  (
                                                    orderVal: any,
                                                    index: number
                                                  ) => (
                                                    <React.Fragment key={index}>
                                                      <tr>
                                                        <td>
                                                          <span className="fs-15 fw-600">
                                                            {'#' +
                                                              orderVal.refKey}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="fs-15 fw-600">
                                                            {
                                                              orderVal.customer
                                                                .name
                                                            }
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="fs-15 fw-600">
                                                            {
                                                              orderVal.variants
                                                                .stockCount
                                                            }
                                                            {orderVal.variants
                                                              .quantityType ===
                                                              CartonWithDozens ||
                                                            orderVal.variants
                                                              .quantityType ===
                                                              CartonWithPieces
                                                              ? 'C'
                                                              : ''}
                                                            {orderVal.variants
                                                              .quantityType ===
                                                            Dozen
                                                              ? 'D'
                                                              : ''}
                                                            {orderVal.variants
                                                              .quantityType ===
                                                            Piece
                                                              ? 'P'
                                                              : ''}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="fs-15 fw-600">
                                                            {'TSh ' +
                                                              Method.getGeneralizedAmount(
                                                                orderVal
                                                                  .variants
                                                                  .stockCount *
                                                                  orderVal
                                                                    .variants
                                                                    .amount
                                                              )}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <Button
                                                            variant="primary"
                                                            className="fs-14 fw-600"
                                                            onClick={() =>
                                                              // navigate(
                                                              //   '/orders/past-delivery-route-details',
                                                              //   {
                                                              //     state:
                                                              //       orderVal._id,
                                                              //   }
                                                              // )
                                                              navigate(
                                                                '/orders/order-details',
                                                                {
                                                                  state: {
                                                                    id: orderVal._id,
                                                                    moduleName:
                                                                      OrdersDelivery,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          >
                                                            View details
                                                          </Button>
                                                        </td>
                                                      </tr>
                                                    </React.Fragment>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <tr>
                                                <td colSpan={9}>
                                                  <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                                    No Data found
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={9}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              No Data found
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default SalesReportV6;
