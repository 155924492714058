import Modal from 'react-bootstrap/Modal';
import CrossSvg from '../../_admin/assets/media/close.png';
import { Button, Col, Row } from 'react-bootstrap';
const ConfirmChanges = (props: any) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-590px"
        className="border-r10px"
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-375px pt-5">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        {props.isRemove ? (
          <Modal.Body className="pt-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="bg-light-danger text-center border-r10px p-3">
                  <span className="fs-18 fw-500">{props.removeMsg}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : (
          <></>
        )}
        <Modal.Footer className="pt-0 pb-8 mb-4 border-top-0">
          <Row className="align-items-center justify-content-center g-3 w-100">
            <Col xs="auto">
              <Button
                variant="primary"
                size="lg"
                onClick={props.onSave}
                disabled={props?.loading}
              >
                {!props.loading && (
                  <span className="indicator-label fs-16 fw-bold">
                    {props.save ? props.save : 'Save'}
                  </span>
                )}
                {props.loading && (
                  <span
                    className="indicator-progress fs-16 fw-bold"
                    style={{ display: 'block' }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="danger"
                size="lg"
                className="btn-lg"
                onClick={props.onHide}
              >
                Discard
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmChanges;
